import * as React from 'react';

import DefaultLayout from '../layout/DefaultLayout';

const NotFoundPage = () => {
  return (
    <DefaultLayout>
      <div>Page not found</div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
